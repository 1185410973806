export const ru =  {
		translation: {
				step_0_text: 'Узнайте, как интеграция искусственного интеллекта сократит затраты вашего бизнеса',
				step_0_start_btn: "Начать",

				step_1_title: "Введите информацию о компании",
				step_1_company_name: "Название компании",
				step_1_company_description: "Короткое описание деятельности",
				step_1_select_sphere: "Выберите сферу деятельности",
				step_1_default_sphere: "Выбрать другое",
				step_1_sphere_placeholder: "Введите сферу деятельности",
				step_1_number_of_employees: "Выберите количество сотрудников",
				step_1_min_symbols_number: '(минимум 30 символов)',
				step_1_sphere_error: 'Cфера деятельности не выбрана',
				step_1_description_error: 'Короткое описание деятельности не заполнено',
				step_1_description_min_error: 'Короткое описание деятельности должно содержать минимум 30 символов',
				step_1_name_error: 'Название компании не заполнено',

				step_2_another: 'Другое',

				step_2_employee_distribution: "Распределите сотрудников по профессиям",
				step_2_amount: "Количество",
				step_2_avg_salary: "Средняя заработная плата в рублях(в месяц на человека)",
				step_2_add_employee: "Если нужно добавить профессию, введите ее название",

				step_3_question_1_option_automated: 'Не автоматизировано',
				step_3_question_1_option_low: 'Слабо',
				step_3_question_1_option_average: 'Средне',
				step_3_question_1_option_high: 'Cильно',
				step_3_question_1_option_everything: 'Автоматизировано всё',

				static_question_1: 'Насколько у вас автоматизированы и регламентированы  процессы в компании?',
				static_question_2: 'Какие системы управления предприятием вы используете (CRM, ERP, Project Management)?',
				
				step_5_additional_questions: "Дополнительные вопросы по отрасли/ компании",

				step_6_analysis_ready: "Расчет готов",
				step_6_company_name: "Название компании",
				step_6_company_description: "Краткое описание компании",
				step_6_employees_number: "Количество сотрудников",
				step_6_employee_distribution: "Распределение сотрудников",
				step_6_send_result_email: "Вы можете получить результаты отчетом в PDF письмом на электронную почту",
				step_6_result_text: "Расчёты AI-Calculator приблизительные: для точного планирования внедрения AI в ваш бизнес необходимо обратиться к ",
				step_6_result_text_traillink: "менеджеру",
				step_6_redo_test: " Пройти тест еще раз",
				step_6_send_email_confirmation_error: "Произошла ошибка при отправке расчета на почту", 
				step_6_send_email_confirmation_success: "Расчет успешно отправлен на почту",
				step_6_people: "чел",

				general_back_btn: "Назад",
				general_next_btn: "Далее",
				general_skip_question: "Пропустить вопрос",
				general_input_placeholder: "Ввести",
				general_idk: "Не знаю",
				general_yes: "Да",
				general_no: "Нет",
				general_answer_not_found: "Ответ не был дан",
				general_analysis: "Анализ",
				general_result: "Результат",
				general_send: "Отправить",
				parent_site_link: "https://raftds.ru",
				parent_site_contacts_link: "https://raftds.ru/contact",
				created_by: "Создано компанией ",
				all_right_received: ". Все права защищены."
		}
};
