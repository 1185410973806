import React, { useEffect, useState } from "react";
import './wizard.css';
import { ReactComponent as MySvg } from '../images/icon.svg';
import BlueCircles from "./BlueCircles";
import { useTranslation } from "react-i18next";

export const Step6 = ({ onNext,
                        companyData,
                        professionData,
                        reportValue,
                        answerOne,
                        answerTwo,
                        questionOne,
                        questionTwo,
                        filledStates,
                        setFilledStates,
                        sendPDF,
                        isFinalStep,
                        setIsFinalStep,
                        questions }) => {

  const [emailIsEmpty, setEmailIsEmpty] = useState(true);
  const {t} = useTranslation()
  function checkEmailEmpty(event) {
    if(event.target.value === "")
        setEmailIsEmpty(true)
    else setEmailIsEmpty(false)
  }

  function formatReport(report) {
    const liPattern = /\d+.\s/g;
    const conclusionsPattern = /Выводы: /;
    const calculationsPattern = /Расчёты: /;
    const conclusionsMatch = report.match(conclusionsPattern);
    report = report.replace(conclusionsMatch, `${conclusionsMatch}<br>`);
    const calculationsMatches = report.match(calculationsPattern);
    report = report.replace(calculationsMatches, `${calculationsMatches}<br>`);
    const liMatches = report.match(liPattern);
    report = report.replace(liMatches, `${liMatches}<br>`);
    return report;
  }

  function validEmail() {
    document.getElementById("email-input").style.borderColor = '#ccc';
  }

  function invalidEmail() {
    document.getElementById("email-input").style.borderColor = 'red';
  }

  function setCurrentStateNext() {
    const changedState = [0, 0, 0, 0];
    return changedState;
  }

  function checkEmailValid() {
    const email = document.getElementById("email-input").value;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }
  
  const handleSubmit = e => {
    e.preventDefault();
    setFilledStates(setCurrentStateNext());
    onNext( "Вопрос №3" ); // передаем профессию вместе с другими данными
    setIsFinalStep(false)
  };

  function reportHandler(report) {
    const percentagePattern = /\d+%/g
    const resultSumPattern = /=\s[-+]?(\d+[.,\s]?)+\sруб/g
    const numberPattern = /[-+]?(\d+[\\.\\,\s]?)+/
    const liPattern = /\d+\.\s/g;
    const conclusionsPattern = /Выводы\:/;
    const calculationsPattern = /Расчёты\:/;
    const percentageMatches = report.match(percentagePattern);
    var resultSumMatches = report.match(resultSumPattern);
    const conclusionsMatch = report.match(conclusionsPattern);
    const calculationsMatches = report.match(calculationsPattern);
    const liMatches = report.match(liPattern);
    var result = report.replace(new RegExp(percentageMatches ? percentageMatches[0] : "", "g"), `<span class='important-report-data'>${percentageMatches ? percentageMatches[0] : ""}</span>`);
    if (resultSumMatches) {
      resultSumMatches = resultSumMatches[resultSumMatches.length - 1].match(numberPattern);
      result = result.replace(new RegExp(resultSumMatches[0], "g"), `<span class='important-report-data'>${resultSumMatches[0]}</span>`);
    }
    if (conclusionsMatch) {
      result = result.replace(conclusionsMatch[0], `<br><br>${conclusionsMatch[0]}`);
    }
    if (calculationsMatches) {
      result = result.replace(calculationsMatches[0], `<br><br>${calculationsMatches[0]}`);
    }
    for(let i of [...new Set(liMatches)]){
        i = i.replace('.', '\\.');
        result = result.replace(new RegExp(i, "g"), `<br>${i}`);
    }
    result = result.replace(new RegExp('\\\\.', "g"), '.');
    return result;
  }

  return(
    <form onSubmit={handleSubmit}>
      <div className="widget-container">
        <div className="logo">
            <div className="logo-wrapper">
                <MySvg />
                <label>AI-Calculator</label>
            </div>
        </div>
      <BlueCircles filledStates={filledStates} />
        <div className="title">
            <p>{t("step_6_analysis_ready")}</p>
        </div>
        <div className={questionOne?.length === 0 || questionTwo?.length === 0 || (questionOne?.length !== 0 && questionTwo?.length !== 0 && reportValue?.length === 0) ? "loader-wrapper" : "loader-wrapper-hide"}>
            <div className="loader"></div>
        </div>
        <div className={questionOne?.length !== 0 && questionTwo?.length !== 0 && reportValue?.length !== 0 ? "result" : "result-hide"}>
            <div className="left-bars">
                <div className="info">
                    <h3>{t("step_6_company_name")}</h3>
                    <p>{companyData.companyName}</p>
                </div>
                <div className="info">
                    <h3>{t("step_6_company_description")}</h3>
                    <p>{companyData.companyDescription}</p>
                </div>
                <div className="info">
                    <h3>{t("step_6_employees_number")}</h3>
                    <p>{companyData.sliderEmployeeValue}</p>
                </div>
                <div className="info">
                    <h3>{t("step_6_employee_distribution")}</h3>
                    {Object.entries(professionData).filter(([key, val]) => key !== 'avarageSalary').map(([key, value]) => (
                        <p>{key}: {Math.round(companyData.sliderEmployeeValue / 100 * professionData[key])} {t("step_6_people")}</p>
                    ))}
                    {/* <p>{professionData}</p> */}
                </div>
                {questions.map(question => <div className="info">
                    <h3>{question.question}</h3>
                    <p>{question.answer}</p>
                </div>)}
            </div>
            <div className="right-bars">
                  {
                    reportValue?.length !== 0 && reportValue['summary'] && 
                    <div className="result-main">
                      <h3>{t("general_result")}</h3>
                      <p id="summary-field" dangerouslySetInnerHTML={{__html: reportHandler(reportValue["summary"])}}></p>
                    </div>
                  }
                <div className="info" id="analysis">
                    <h3>{t("general_analysis")}</h3>
                    { reportValue?.length !== 0 && 
                    <p id="report-field" dangerouslySetInnerHTML={{__html: reportHandler(reportValue["report"])}}></p>}
                </div>
            </div>
        </div>
        
        <div className={questions.length !== 0 && reportValue?.length !== 0 ? "pdf-bar" : "pdf-bar-hide"}>
            <label>{t("step_6_send_result_email")}</label>
            <div className="pdf-bar-buttons">
                <input type="text" placeholder="example@mail.com" id="email-input" onChange={e => {
                    checkEmailEmpty(e);
                }}></input>
                <button type="button" disabled={emailIsEmpty} id="send-email" onClick={() => {
                    if(checkEmailValid()){
                        validEmail();
                        sendPDF(document.getElementById("email-input").value);
                    }
                    else {
                        invalidEmail();
                    }
                }}>{t("general_send")}</button>
            </div>
        </div>

        <label id="bottom-text">{t("step_6_result_text")}<a href={t("parent_site_contacts_link")}>{t("step_6_result_text_traillink")}</a></label>
        <div className="repeat-bar">
            <button id="repeat-test" type="submit" >{t("step_6_redo_test")}</button>
        </div>       
      </div>

    </form>
  );
}
