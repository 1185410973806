import React, { useState } from 'react';

function BlueCircles({ filledStates }) {
  
  return (
    <div className="blue-circes">
        {filledStates.map((state, index) => (
            <>
              <div className={state === 0 ? "circle-empty" : "circle"}/>
              {index < filledStates.length - 1 && <div className="line"></div>}
            </>
        ))}           
    </div>
  );
}

export default BlueCircles;
