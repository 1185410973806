import React, { useEffect, useState } from "react";
import './wizard.css';
import { Range, getTrackBackground } from "react-range";
import { ReactComponent as MySvg } from '../images/icon.svg';
import BlueCircles from "./BlueCircles";
import { useTranslation } from "react-i18next";

const EMPLOYEE_NUMBER_MIN = 1;

const errors = {
    sphere_error: 'sphere_error',
    description_error: 'description_error',
    description_min_error: 'description_min_error',
    name_error: 'name_error'
}

const spheresShow = [
        {en: 'IT', id: 'IT', ru: 'IT'},
        {en: 'RealEstate', id: 'RealEstate', ru: 'Недвижимость'},
        {en: 'Advertising', id: 'Advertising', ru: 'Маркетинг'},
    ]
    
    const spheresSearch = [
        {en: 'Healthcare',id: 'Healthcare', ru: 'Медицина'},
        {en: 'Finance', id: 'Finance', ru: 'Финансы'},
        {en: 'Manufacturing', id: 'Manufacturing', ru: 'Производство'},
        {en: 'Retail', id: 'Retail', ru: 'Торговля'},
        {en: 'Automotive', id: 'Automotive', ru: 'Авто'},
        {en: 'Telecom', id: 'Telecom', ru: 'Связь'},
        {en: 'Travel', id: 'Travel', ru: 'Туризм'},
        {en: 'Education', id: 'Education', ru: 'Образование'},
        {en: 'Energy', id: 'Energy', ru: 'Энергетика'},
        {en: 'Media', id: 'Media', ru: 'Медиа'},
        {en: 'FoodBeverage', id: 'FoodBeverage', ru: 'Продукты питания'},
        {en: 'Logistics', id: 'Logistics', ru: 'Логистика'},
        {en: 'LegalServices', id: 'LegalServices', ru: 'Юридические услуги'},
        {en: 'Environment', id: 'Environment', ru: 'Охрана окружающей среды'},
        {en: 'Entertainment', id: 'Entertainment', ru: 'Искусство'},
        {en: 'Consulting', id: 'Consulting', ru: 'Консалтинг'},
        {en: 'Agriculture', id: 'Agriculture', ru: 'Сельское хозяйство'},
    ];

export const Step1 = ({ onNext, companyData, onBack, filledStates, setFilledStates }) => {
    const maxEmployees = 35000;
    const {t, i18n} = useTranslation()
    const [companyName, setCompanyName] = useState(companyData?.companyName || "");
    const [companyDescription, setCompanyDescription] = useState(companyData?.companyDescription || "");
    const [companySphere, setCompanySphere] = useState(companyData?.companySphere || []); // теперь сферы - это массив
    const [sliderEmployeeValue, setEmployeeValue] = useState(companyData?.sliderEmployeeValue || EMPLOYEE_NUMBER_MIN); //Задаем начальное значение
    const [currLength, setCurrCompayInfo] = useState(0);
    const [sliderState, setSliderState] = useState([companyData?.sliderEmployeeValue || EMPLOYEE_NUMBER_MIN]);
    const [fieldsChecked, setEnableButton] = useState(true);
    const [another, setAnother] = useState(() => {
        if(companyData?.companySphere) {
            return !spheresShow.some(sphere => sphere[i18n.language] === companyData?.companySphere) && !spheresSearch.some(sphere => sphere[i18n.language] === companyData?.companySphere)  
        }

        return false;
    });

    const [error, setError] = useState('')
    
    const handleCompanySphereChange = (event) => { // функция для обработки изменения сферы
        document.getElementById('another').checked = true;
        // const selectedOptions = Array.from(event.target.options)
        const selectedOption = event.target.value;
        // .filter(option => option.selected)
        // .map(option => option.value)
        setCompanySphere(selectedOption);
        setEnableButton(checkFields());
        setAnother(false)
        setError('')
    };

    const handleSubmit = e => {
        e.preventDefault();
        if(checkFields({ setError: true})) {
            return;
        }
        setFilledStates(setCurrentState());
        onNext({ companyName, companyDescription, companySphere,  sliderEmployeeValue});
    };

    function setCurrentState() {
        const changedState = filledStates;
        changedState[1] = 1;
        return changedState;
    }

    const minDescriptionLength = 30;
    const limitCnt = 100;

    function checkFields(options = { setError: false }) {
        if (document.getElementById("companyName").value.trim().length === 0){
            console.log('first')
            if(options.setError) {
                setError(errors.name_error)
            } else {
                setError('')
            }
            return true;
        }
        if(document.getElementById("companyInfo").value.trim().length === 0){
            if(options.setError) {
                setError(errors.description_error)
            } else {
                setError('')
            }
            return true;
        }
        if ( document.getElementById("companyInfo").value.trim().length < minDescriptionLength){
            if(options.setError) {
                setError(errors.description_min_error)
            } else {
                setError('')
            }
            return true;
        }
        if (sliderState[0] < EMPLOYEE_NUMBER_MIN){
            return true;
        }
        if (!document.querySelector('input[name="sphere"]:checked') && !another) {
            if(options.setError) {
                setError(errors.sphere_error)
            } else {
                setError('')
            }
            return true;
        }
        if (another && !companySphere) {
            if(options.setError) {
                setError(errors.sphere_error)
            } else {
                setError('')
            }
            return true;
        }
        return false;
    }

    function changeCompanyInfo(event) {
        setCompanyDescription(event.target.value);
        const length = event.target.value.length;
        setCurrCompayInfo(length);
        setEnableButton(checkFields());
    }

    function changeCompanyName(event){
        setCompanyName(event.target.value);
        setEnableButton(checkFields());
    }

    useEffect(() => {
        setEnableButton(checkFields());
    }, [sliderState]);
    
    return(
        <form onSubmit={handleSubmit}>
            <div className="widget-container">
                <div className="logo">
                    <div className="logo-wrapper">
                        <MySvg />
                        <label>AI-Calculator</label>
                    </div>
                </div>
                <BlueCircles filledStates={filledStates} />
                <div className="title">
                    <p>{t("step_1_title")}</p>
                </div>

                {/* Название компании */}
                <div>
                    <div className='input-group'>
                        <label>{t("step_1_company_name")}<span aria-hidden="true" className="required">*</span></label>
                        <input id="companyName" type="text" value={companyName} onChange={e => {
                            if(e.target.value) {
                                setError('')
                            } else {
                                setError(errors.name_error)
                            }
                            changeCompanyName(e)}} placeholder={t('general_input_placeholder')}
                        />
                    </div>
                    {error === errors.name_error && <p className="error-text">
                        {t('step_1_name_error')}
                    </p>}
                </div>
                

                {/* Описание компании */}
                <div>
                    <div className='input-group'>
                        <label id="cal">{t("step_1_company_description")}
                        <span aria-hidden="true" className="required">*</span>
                        <span aria-hidden="true" className="minLength">{t("step_1_min_symbols_number")}</span>
                        </label>
                        <textarea 
                            type="text" 
                            value={companyDescription} 
                            onChange={e => {
                                if(e.target.value) {
                                    setError('')
                                } else {
                                    setError(errors.description_error)
                                }
                                changeCompanyInfo(e)
                                }} 
                            placeholder={t('general_input_placeholder')}
                            id="companyInfo"
                            maxLength={limitCnt}
                            minLength={30}
                        />
                        <div className="companyInfoLength">
                            <span id="currLength">{currLength}</span>
                            <span>/</span>
                            <span id="limitLength">{limitCnt}</span>
                        </div>
                    </div>
                    {error === errors.description_error && <p className="error-text">
                        {t('step_1_description_error')}
                    </p>}
                    {error === errors.description_min_error && <p className="error-text">
                        {t('step_1_description_min_error')}
                    </p>}
                </div>
                

                {/* Сфера деятельности */}
                <div className="input-group">
                    <label>{t("step_1_select_sphere")} <span aria-hidden="true" class="required">*</span></label>
                    <div className="showSpheres_stp1">
                        {spheresShow.map((sphere) => (
                            <><input type="radio" name="sphere" value={sphere[i18n.language]} id={sphere[i18n.language]} 
                            onChange={() => {
                                    setCompanySphere(document.querySelector('input[name="sphere"]:checked').value);
                                    
                                    setEnableButton(checkFields());
                                    setAnother(false)
                                    setError('')
                                }   
                            }
                            checked={sphere[i18n.language] === companySphere}
                            />
                            <label className="selectOption" htmlFor={sphere[i18n.language]}>
                                {sphere[i18n.language]}
                            </label></>
                        ))}
                        <input type="radio" name="sphere" value="another" id="another" checked={spheresSearch.some(sphere => sphere[i18n.language] === companySphere )}/>
                        <label className="selectOption" htmlFor="another">
                            <select id="sphere-select" onChange={handleCompanySphereChange} value={companySphere}>
                                <option value={t('step_2_another')} defaultValue>{t("step_1_default_sphere")}</option>
                                {spheresSearch.map((sphere) => (
                                    <option value={sphere[i18n.language]}>{sphere[i18n.language]}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div className="customSphereWrapper">
                        <input type="checkbox" name="another" value={another} onChange={()=>{setAnother(!another) 
                        setCompanySphere('')}} checked={another}/>
                        <input id="custom-sphere" type="text" placeholder={t("step_1_sphere_placeholder")} disabled={!another} onChange={(e) => {
                            setCompanySphere(e.target.value);
                            if(e.target.value) {
                                setError('')
                            } else {
                                setError(errors.sphere_error)
                            }
                        }} value={another ? companySphere : ""}/>
                    </div>
                    {error === errors.sphere_error && <p className="error-text">
                        {t('step_1_sphere_error')}
                    </p>}
                </div>
                
                {/* Количество сотрудников */}
                <div className="input-group">
                    <label>{t("step_1_number_of_employees")}
                    <span aria-hidden="true" class="required">*</span>
                    </label>
                    {/* <ReactRange id="empsCnt"/> */}
                    <div
                        style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        margin: "2em",
                        alignItems: "center"
                        }}
                    >
                    <span>{EMPLOYEE_NUMBER_MIN}</span>
                    <Range
                        values={sliderState}
                        step={1}
                        min={EMPLOYEE_NUMBER_MIN}
                        max={maxEmployees}
                        onChange={(values) => {
                            setSliderState(values);
                            setEmployeeValue(values[0])
                        }
                        }
                        renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                            ...props.style,
                            height: '36px',
                            display: 'flex',
                            width: '75%'
                            }}
                        >
                            <div
                            ref={props.ref}
                            style={{
                                height: '5px',
                                width: '100%',
                                borderRadius: '4px',
                                background: getTrackBackground({
                                values: sliderState,
                                colors: ['#506cff', '#ccc'],
                                min: 0,
                                max: maxEmployees,
                                }),
                                alignSelf: 'center'
                            }}
                            >
                            {children}
                            </div>
                        </div>
                        )}
                        renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                            ...props.style,
                            height: '18px',
                            width: '18px',
                            borderRadius: '50%',
                            backgroundColor: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0px 2px 6px #AAA',
                            border: 'solid',
                            borderColor: '#506cff',
                            outline: 'none'
                            }}
                        >
                            <div
                            style={{
                                position: 'absolute',
                                top: '-28px',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '14px',
                                fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                                padding: '4px',
                                borderRadius: '4px',
                                backgroundColor: '#506cff'
                            }}
                            >
                            {sliderState[0] || 0}
                            </div>
                            <div
                            style={{
                                height: '5px',
                                width: '5px',
                                backgroundColor: 'white'
                            }}
                            />
                        </div>
                        )}
                    />
                    <span>{maxEmployees}</span>
                    <input type="number" min={0} max={maxEmployees} onChange={e => {
                            const newValue = e.target.value;
                            if(newValue > maxEmployees || newValue < 0) {
                                return;
                            }
                            
                            setSliderState([newValue]);
                            setEmployeeValue(newValue)
                        }}  value={sliderState[0]}/>
                    </div>
                </div>
                
                {/* Переходы к следующему и предыдущиму шагам */}
                <div className="navSection">
                    <button type="button" onClick={onBack}>&larr; &nbsp; {t("general_back_btn")}</button>
                    <button id="next" type="submit">{t("general_next_btn")} &nbsp; &rarr;</button>
                </div>
            </div>
        </form>
    );
}
