export const en =  {
		translation: {
				step_0_text: 'Find out how AI integration reduces cost for your business',
				step_0_start_btn: "Start",

				step_1_title: "Enter company information",
				step_1_company_name: "Company name",
				step_1_company_description: "Brief description of activity",
				step_1_select_sphere: "Select your field of activity",
				step_1_default_sphere: "Select another",
				step_1_sphere_placeholder: "Enter your field of activity",
				step_1_number_of_employees: "Select the number of employees",
				step_1_min_symbols_number: '(minimum number of symbols: 30)',
				step_1_sphere_error: 'Field of activity is required',
				step_1_description_error: 'Brief description of activity is not filled in',
				step_1_description_min_error: 'A short description of the activity must contain at least 30 characters',
				step_1_name_error: 'Company name is not entered',

				step_2_another: 'Another',
				step_2_employee_distribution: "Categorize employees based on their roles",
				step_2_amount: "Number of people",
				step_2_avg_salary: "Average monthly salary $",
				step_2_add_employee: "For adding a new profession, fill in its name",

				step_3_question_1_option_automated: 'Not automated',
				step_3_question_1_option_low: 'Low level',
				step_3_question_1_option_average: 'Average level',
				step_3_question_1_option_high: 'High level',
				step_3_question_1_option_everything: 'Everything is automated',

				static_question_1: 'How automated and regulated are your company processes?',
				static_question_2: 'What enterprise management systems do you use (CRM, ERP, Project Management)?',

				step_5_additional_questions: "Additional questions about the industry/company",

				step_6_analysis_ready: "The calculation is ready",
				step_6_company_name: "Company name",
				step_6_company_description: "Brief description of the company",
				step_6_employees_number: "The number of employees",
				step_6_employee_distribution: "Employee distribution",
				step_6_send_result_email: "You can receive the results as a report in PDF by email",
				step_6_result_text: "AI-Calculator generates approximate data, to accurately plan the integration of AI to your business, you need to contact your manager",
				step_6_result_text_traillink: "manager",
				step_6_redo_test: "Perform another calculation",
				step_6_send_email_confirmation_error: "An error occurred while sending the calculation by email.", 
				step_6_send_email_confirmation_success: "The calculation was successfully sent by email",
				step_6_people: "people",

				general_back_btn: "Back",
				general_next_btn: "Next",
				general_skip_question: "Skip the question",
				general_input_placeholder: "Enter value",
				general_idk: "I do not know",
				general_yes: "Yes",
				general_no: "No",
				general_answer_not_found: "The answer is not found",
				general_analysis: "Analysis",
				general_result: "Result",
				general_send: "Send",
				parent_site_link: "https://raftds.com",
				parent_site_contacts_link: "https://raftds.com/contacts",
				created_by: "Created by ",
				all_right_received: ". All right received."

		}
};