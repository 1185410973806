import React from 'react';
import Wizard from './Wizard'; // Убедитесь, что путь к файлу правильный
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Wizard />
      <ToastContainer   hideProgressBar/>
    </div>
  );
}

export default App;