import './wizard.css';
import { ReactComponent as MySvg } from '../images/icon.svg';
import { useTranslation } from 'react-i18next';

export const Step0 = ({ onNext }) => {
    const {t} = useTranslation()
    const handleSubmit = e => {
        e.preventDefault();
        onNext({ });
    };
    
    return(
        <form onSubmit={handleSubmit}>
            <div className="widget-container">
                <div className="start-wrapper">
                    <div className="logo">
                        
                        <div className="logo-wrapper">
                            <MySvg />
                            <label>AI-Calculator</label>
                        </div>
                    </div>
                    <div className="start-text">
                        <p> 
                            {t("step_0_text")}
                        </p>
                    </div>
                    <div className="start-button">
                        <button id="next" type="submit" >{t("step_0_start_btn")} &nbsp; &rarr;</button>
                    </div>
                </div>
            </div>
        </form>
    );
}