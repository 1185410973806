import React, { useState } from "react";
import './wizard.css';
import { ReactComponent as MySvg } from '../images/icon.svg';
import BlueCircles from "./BlueCircles";
import { useTranslation } from "react-i18next";
import { QUESTIONS_START_INDEX } from "../constants";

export const Step3 = ({ onNext, questionOne, onBack, filledStates, setFilledStates, step, questionsLength, resetQuestions, loadingQuestions }) => {
  const [answerOneValue, setAnswerOneValue] = useState('');
  const [fieldsChecked, setEnableButton] = useState(true);
  const {t} = useTranslation()

  function checkFields() {
    if(step - QUESTIONS_START_INDEX === questionsLength - 1) {
      if(!document.querySelector('input[name="answers"]').value) {
        return true
      }
    } else if (!document.querySelector('input[name="answers"]:checked')){
        return true;
    }
    
    return false;
  }
  
  const handleSubmit = e => {
    e.preventDefault();
    if(step - QUESTIONS_START_INDEX === questionsLength - 1){
      setFilledStates(setCurrentStateNext());
    }
    if(step - QUESTIONS_START_INDEX === questionsLength - 1 ) {
      onNext( answerOneValue ? answerOneValue : t("general_answer_not_found") );
    } else {
      onNext( document.querySelector('input[name="answers"]:checked') ? document.querySelector('input[name="answers"]:checked').value : t("general_answer_not_found") );
    }
    
  };

  const handleSkip = e => {
    e.preventDefault();
    if(step - QUESTIONS_START_INDEX === questionsLength - 1){ 
      setFilledStates(setCurrentStateNext('skip'));
    }
    onNext( t("general_answer_not_found") );
  }

  const handleBack = () => {
    if(step - QUESTIONS_START_INDEX === 0){
      resetQuestions()
      setFilledStates(setCurrentStateBack());
    }
    onBack();
    
  }

  function setCurrentStateNext(tag) {
    const changedState = filledStates;
    if (tag === 'skip' || checkFields()){
      changedState[2] = 0;
    }
    else {
      changedState[2] = 1;
    }
    changedState[3] = 1;
    return changedState;
  }

  function setCurrentStateBack() {
    const changedState = filledStates;
    if (checkFields()){
      changedState[2] = 0;
    }
    else {
      changedState[2] = 1;
    }
    changedState[1] = 1;
    return changedState;
  }

  return(
    <form onSubmit={handleSubmit}>
      <div className="widget-container">
       <div className="logo">
          <div className="logo-wrapper">
              <MySvg />
              <label>AI-Calculator</label>
          </div>
      </div>
      <BlueCircles filledStates={filledStates} />
      <div className="title">
          <p>{questionOne}</p>
      </div>
      <div className={questionOne.length === 0 ? "loader-wrapper" : "loader-wrapper-hide"}>
        <div className="loader"></div>
      </div>
        <div className={questionOne.length !== 0 ? "answer" : "answer-hide"}>
          <div className="showSpheres">
            {step - QUESTIONS_START_INDEX === questionsLength - 2 && <> 
            <input type="radio" name="answers" value={t("step_3_question_1_option_automated")} id="Automated"
            onChange={() => {
                  setAnswerOneValue(document.querySelector('input[name="answers"]:checked').value);
                  setEnableButton(checkFields());
              }
            }
          />
            <label className="selectOption" for="Automated">
                {t("step_3_question_1_option_automated")}
            </label>
              <input type="radio" name="answers" value={t("step_3_question_1_option_low")} id="Low"
            onChange={() => {
                  setAnswerOneValue(document.querySelector('input[name="answers"]:checked').value);
                  setEnableButton(checkFields());
              }
            }
          />
            <label className="selectOption" for="Low">
                {t("step_3_question_1_option_low")}
            </label>
              <input type="radio" name="answers" value={t("step_3_question_1_option_average")} id="Average"
            onChange={() => {
                  setAnswerOneValue(document.querySelector('input[name="answers"]:checked').value);
                  setEnableButton(checkFields());
              }
            }
          />
            <label className="selectOption" for="Average">
                {t("step_3_question_1_option_average")}
            </label>
              <input type="radio" name="answers" value={t("step_3_question_1_option_high")} id="High"
            onChange={() => {
                  setAnswerOneValue(document.querySelector('input[name="answers"]:checked').value);
                  setEnableButton(checkFields());
              }
            }
          />
            <label className="selectOption" for="High">
                {t("step_3_question_1_option_high")}
            </label>
              <input type="radio" name="answers" value={t("step_3_question_1_option_everything")} id="Everything"
            onChange={() => {
                  setAnswerOneValue(document.querySelector('input[name="answers"]:checked').value);
                  setEnableButton(checkFields());
              }
            }
          />
            <label className="selectOption" for="Everything">
                {t("step_3_question_1_option_everything")}
            </label>
            </>}
            {step - QUESTIONS_START_INDEX === questionsLength - 1 && <> <input type="text" name="answers" value={answerOneValue} id="Question2"
            onChange={(event) => {
                  setAnswerOneValue(event.target.value);
                  setEnableButton(checkFields());
              }
            }
          />  </>}
            {step - QUESTIONS_START_INDEX < questionsLength - 2 && <>
                    <input type="radio" name="answers" value={t("general_yes")} id="Yes"
            onChange={() => {
                  setAnswerOneValue(document.querySelector('input[name="answers"]:checked').value);
                  setEnableButton(checkFields());
              }
            }
          />
            <label className="selectOption" for="Yes">
                {t("general_yes")}
            </label>
          <input type="radio" name="answers" value={t("general_no")} id="No"
            onChange={() => {
                setAnswerOneValue(document.querySelector('input[name="answers"]:checked').value);
                setEnableButton(checkFields());
              }
            }
          />
            <label className="selectOption" for="No">
              {t("general_no")}
            </label>
          <input type="radio" name="answers" value={t("general_idk")} id="Idk"
            onChange={() => {
                setAnswerOneValue(document.querySelector('input[name="answers"]:checked').value);
                setEnableButton(checkFields());
              }
            }
          />
            <label className="selectOption" for="Idk">
                {t("general_idk")}
            </label>
            </>}
        
          </div>
        </div>

        <div className="navSection">
          <button type="button" onClick={() => handleBack()} disabled={loadingQuestions}>&larr; &nbsp; {t("general_back_btn")}</button>
          <div>
            <button id="skip" onClick={handleSkip} disabled={loadingQuestions}>{t("general_skip_question")}</button>
            <button id="next" type="submit" disabled={fieldsChecked}>{t("general_next_btn")} &nbsp; &rarr;</button>
          </div>
        </div>
      </div>

    </form>
  );
}
