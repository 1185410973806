import React, { useState } from 'react';
import axios from 'axios';
import { Step0 } from './Step0';
import { Step1 } from "./Step1";
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step6 } from './Step6';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { QUESTIONS_START_INDEX, BACKEND_DOMAIN } from '../constants';


function Wizard() {
  const [step, setStep] = useState(0);
  const [companyData, setCompanyData] = useState(null);
  const [professionData, setProfessionData] = useState({});
  const [questionOne, setQuestinonOne] = useState('');
  const [questionTwo, setQuestinonTwo] = useState('');
  const [filledStates, setFilledStates] = useState([0, 0, 0, 0]);
  const [answerOne, setAnswerOne] = useState('');
  const [answerTwo, setAnswerTwo] = useState('');
  const [reportValue, setReportValue] = useState('');
  const [questions, setQuestions] = useState([]);
  const [isFinalStep, setIsFinalStep] = useState(false)
  const [loadingQuestions, setLoadingQuestions] = useState(false)
  const [loading, setLoading] = useState(false)
  const {t} = useTranslation();

  const staticQuestions = [{question: t("static_question_1"), answer: undefined},
     {question: t("static_question_2"), answer: undefined}];
  
  const handleCompanyNext = companyData => {
    setCompanyData(companyData); // Сохраняем информацию о компании
    setLoading(true);
    axios.post(BACKEND_DOMAIN + '/guess_profession', companyData)
      .then(response => {
        setProfessionData(response.data); // Получаем список профессий и записываем состояние
      })
      .catch(error => {
        console.error('Error while sending data to server: ', error);
      }).finally(()=>setLoading(false));
    
    setStep(2); // Переходим на новый шаг
  };

  const handleProfessionNext = professionData => {
    setLoadingQuestions(true);
    axios.post(BACKEND_DOMAIN + '/questions_generate', {companyData, professionData})
      .then(response => {
        const data = response.data
        const restQuestions = Object.entries(data).map(question => ({question: question[1], answer: undefined}));
        setQuestions(restQuestions.concat(staticQuestions.map((question, index) => ({...question, question: `${restQuestions.length + index + 1}. ${question.question}`}))));
        setQuestinonOne(data['first_question'])
        setQuestinonTwo(data['second_question'])
      })
      .catch(error => {
        console.error('Error while sending data to server: ', error);
    }).finally(()=>setLoadingQuestions(false));

    setStep(3); // Переходим на новый шаг
  };

  const handleNextQuestion = (answerValue) => {
    const updatedQuestions = questions.map((question, index) => {
      if(step - QUESTIONS_START_INDEX === index) {
        return {
          ...question,
          answer: answerValue
        }
      }
      return question
    });
    const nextStep = step + 1;

    setQuestions(updatedQuestions);
    setStep(nextStep);

    if(step - QUESTIONS_START_INDEX === questions.length - 1) {
      const post_data = {
        companyData, 
        professionData, 
        ...(updatedQuestions.reduce((acc, question, index) => ({
          ...acc,
          [`question_${index}`]: question.question,
          [`answer_${index}`]: question.answer
        }),{})),
      }

      axios.post(BACKEND_DOMAIN + '/report_creation', post_data)
        .then(response => {
          setReportValue(response.data)
          setIsFinalStep(true)
        })
        .catch(error => {
          console.error('Error while sending data to server: ', error);
      });
    }
  }

  const sendPDF = (sendTo) => {
    const post_data = {
      companyData,
      reportValue,
      "send-to": sendTo
    }

    axios.post(BACKEND_DOMAIN + '/send_pdf', post_data)
    .then(response => {
      console.log("response: ", response);
      if(response.status === 200) {
        toast.success(t("step_6_send_email_confirmation_success"));
      } else {
        toast.error(t("step_6_send_email_confirmation_error"));
      }
    })
      .catch(error => {
        toast.error(t("step_6_send_email_confirmation_error"));
        console.error('Error while sending data to server: ', error);
    });
  }

  const start = startData => {
    setCompanyData(null);
    setProfessionData({});
    setQuestinonOne('');
    setQuestinonTwo('');
    setAnswerOne('');
    setAnswerTwo('');
    setReportValue('');
    setStep(1);
    setFilledStates(prevState => prevState.map((state, index) => index === 0 ? 1 : state))
  }

  const handleBack = () => {
    setStep(step - 1);
  };

  return (
    <div className='container'>
      {step === 0 && <Step0 onNext={start} />}
      {step === 1 && <Step1 onNext={handleCompanyNext} companyData={companyData} filledStates={filledStates} setFilledStates={setFilledStates} onBack={handleBack}/>}
      {step === 2 && <Step2 loading={loading} onBack={handleBack} onNext={handleProfessionNext} professionData={professionData} filledStates={filledStates} setFilledStates={setFilledStates}/>}
      {step >= QUESTIONS_START_INDEX && !isFinalStep && <Step3 key={step} onBack={handleBack} onNext={handleNextQuestion} 
        questionOne={questions[step - QUESTIONS_START_INDEX]?.question || ""} filledStates={filledStates} setFilledStates={setFilledStates} step={step} 
        questionsLength={questions.length} resetQuestions={() => setQuestions([])} loadingQuestions={loadingQuestions}/>}
      {isFinalStep && <Step6 onBack={handleBack} 
                            professionData={professionData}
                            reportValue={reportValue}
                            answerOne={questions[0]['answer']}
                            answerTwo={questions[1]['answer']}
                            questionOne={questions[0]['question']}
                            questionTwo={questions[1]['question']}
                            onNext={start}
                            companyData={companyData}
                            filledStates={filledStates}
                            setFilledStates={setFilledStates}
                            sendPDF={sendPDF}  
                            isFinalStep={isFinalStep}
                            setIsFinalStep={setIsFinalStep} 
                            questions={questions}/>}
      <div className='footer'>                      
        {t("created_by")}<a href={t("parent_site_link")}>Raft</a>{t("all_right_received")}
      </div>
    </div>
  );

}

export default Wizard;